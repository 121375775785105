import './Home.css';
import Hover from '../../sections/hover/Hover';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const Home = () => {
    const location = useLocation();

    useEffect(() => {
        const handleHashScroll = () => {
            if (location.hash) {
                const element = document.getElementById(location.hash.substring(1));
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }
        };

        handleHashScroll(); // Scroll on initial load

        // Listen for hash changes
        window.addEventListener('hashchange', handleHashScroll);

        // Clean up event listener
        return () => {
            window.removeEventListener('hashchange', handleHashScroll);
        };
    }, [location]);
    return (
        <>
            <Hover/>
        </>
    )
}

export default Home;
